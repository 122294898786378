.ModalWrapper {
    align-content: center;
}

.NounImg {
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    width: 100%;
    max-width: 500px;
    border-radius: 15px;
}

.Footer {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 18px;
}

h3 {
    font-size: xx-large;
    font-weight: bold;
    margin-top: 8px;
}