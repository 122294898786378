.img {
    image-rendering: pixelated;
    image-rendering: -moz-crisp-edges;
    width: 100%;
    height: 100%;
    max-width: 500px;
}

@media only screen and (min-width: 500px) {
    .img {
        width: 500px;
    }
}

.imgWrapper {
    display: flex;
    justify-content: center;
}