button {
    padding: 0;
}


.voteButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
    opacity: 0.9;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.10);
    border-radius: 20px;
    border: 0;

    height: 110px;
    width: 50%;
    min-width: 85px;
    max-width: 250px;

    box-sizing: border-box;
}

@media (hover: hover) {
    .voteButton:enabled:hover:not(.selected) {
        background: rgba(0, 0, 0, 0.1);
    }
}

.voteText {
    font-size: 1.5em;
    font-weight: bold;
}

.voteEmojiText {
    font-size: 3em;
}

.selected {
    opacity: 1;
    background-color: rgb(127, 197, 156, .7);
}

.selectedLike {
    opacity: 1;
    background-color: #6CD296;
}

.selectedDislike {
    opacity: 1;
    background-color: #EC7474;
}